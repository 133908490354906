// import './App.css';
// import PlayStore from './assets/play_store.svg';

// function App() {
//   const imageClick = () => {
//     window.open("https://play.google.com/store/apps/details?id=com.dinagenk.nusabook"); 
//   } 

//   return (
//     <div className="App">
//       <div className='Tagline'>
//         <h1>Nusabook</h1>
//         <h4>Beli tiket lebih mudah.</h4>
//         <img src={PlayStore} onClick={() => imageClick()}/>
//       </div>
//     </div>
//   );
// }

// export default App;

// import './App.css';
// import PlayStore from './assets/play_store.svg';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Privacy from "./pages/privacy/Privacy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="privacy" element={<Privacy/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

