import "./home.css";
import PlayStore from "../../assets/play_store.svg";

function Home() {
  const imageClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.dinagenk.nusabook"
    );
  };
  return (
    <div className="App">
      <div className="Tagline">
        <h1 className="head">Nusabook</h1>
        <h4 className="tag">Beli tiket lebih mudah.</h4>
        <img className="tombolPlayStore" src={PlayStore} onClick={() => imageClick()} alt="play store"/>
      </div>
    </div>
  );
}

export default Home;
